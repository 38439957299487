
import React, { useState ,useEffect} from 'react';
import {
    IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonInput, IonLabel, IonRow, IonCol, IonModal, IonRadio, IonRadioGroup, IonItem,IonAlert
} from '@ionic/react';

export const PalletItemModal = ({
    modal,
    showModal,
    onBarcodeBlur,
    onModalFieldChange,
    userName,
    onDoneClick,
    closeModal,
    handleFieldChange,
    sendingInner,
    setSendingInner,
    handleDidNumberBlur, // Receive the new prop
    // handleItoNumberBlur  // Receive the new prop
}) => {
    const [alertMessage, setAlertMessage] = useState(''); // Define state for alert message
    const [showAlert, setShowAlert] = useState(false);    // Define state for showing alert
    // Effect to handle alert display when condition is met
    // useEffect(() => {
    //     if (modal.quantity > modal.cartons_send) {
    //         setAlertMessage('Quantity cannot be greater than Cartons to send.');
    //         setShowAlert(true);
    //     }
    // }, [modal.quantity, modal.cartons_send]);  // Dependencies to watch for changes
    let Quantity;
    if (modal.sendingInner === 'True') {
        Quantity = modal.quantity * modal.inner;
    } else {
        Quantity = modal.quantity * modal.outer;
    }
    // const isQuantityExceeded = Quantity > modal.oh_quantity;
  


    
    return (
        <>
        <IonModal isOpen={showModal} backdropDismiss={false} onDidDismiss={closeModal} className='pallet-modal'>
            <IonCard style={{ maxHeight: '90vh', overflowY: 'auto' }}>
                <IonCardHeader>
                    <IonCardTitle>{modal.id && modal.id > 0 ? 'Update Item' : 'Add Item'}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCard>
                        <IonCardContent>
                            {/* Radio buttons in a single line */}
                            <IonRow>
                                <IonCol>
                                    <IonItem lines="none">
                                        <IonLabel>Sending</IonLabel>
                                        <IonRadioGroup
                                             value={sendingInner ? 'inner' : 'outer'}
                                             onIonChange={(e) => {
                                                 const isInner = e.detail.value === 'inner';
                                                 setSendingInner(isInner);
                                                 handleFieldChange('sendingInner', isInner);
                                             }}
                                             style={{ display: 'flex' }}
                                        >
                                            
                                            <IonItem lines="none">
                                                <IonRadio slot="start" value="inner" />
                                                <IonLabel>Inner</IonLabel>
                                            </IonItem>
                                            <IonItem lines="none" >
                                                <IonRadio slot="start" value="outer" />
                                                <IonLabel>Outer</IonLabel>
                                            </IonItem>
                                        </IonRadioGroup>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            {/* Conditionally Render Input fields for DID Number and ITO Number */}
                            {/* {!modal.ito_Number && (  // Hide if ito_Number has any value */}
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>DID Number</IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonInput 
                                            name='did_Number' 
                                            value={modal.did_Number} 
                                            onIonChange={(e) => onModalFieldChange(e.target.name, e.target.value)} 
                                            onIonBlur={handleDidNumberBlur} // Call onBlur handler
                                        />
                                    </IonCol>
                                </IonRow>
                            {/* )} */}
                            {/* {!modal.did_Number && (  // Hide if did_Number has any value
                                <IonRow>
                                    <IonCol>
                                        <IonLabel>ITO Number</IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonInput 
                                            name='ito_Number' 
                                            value={modal.ito_Number} 
                                            onIonChange={(e) => onModalFieldChange(e.target.name, e.target.value)} 
                                            onIonBlur={handleItoNumberBlur} // Call onBlur handler
                                        />
                                    </IonCol>
                                </IonRow>
                            )} */}
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Barcode</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput 
                                        name='barcode' 
                                        value={modal.barcode}
                                        onIonBlur={onBarcodeBlur}
                                        onIonChange={(e) => onModalFieldChange(e.target.name, e.target.value)} 
                                    />
                                </IonCol>
                            </IonRow>


                            <IonRow>
                                <IonCol>
                                    <IonLabel>OnHand Quantity</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput 
                                    disabled
                                        name='oh_quantity' 
                                        value={modal.oh_quantity} 
                                        onIonChange={(e) => onModalFieldChange(e.target.name, e.target.value)} 
                                    />
                                </IonCol>
                            </IonRow>



                            <IonRow>
                                <IonCol>
                                    <IonLabel>Description</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput 
                                    disabled
                                        name='description' 
                                        value={modal.description} 
                                        onIonChange={(e) => onModalFieldChange(e.target.name, e.target.value)} 
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Outer</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput 
                                    disabled
                                        name='outer' 
                                        value={modal.outer} 
                                        onIonChange={(e) => onModalFieldChange(e.target.name, e.target.value)} 
                                    ></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Inner</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput 
                                    disabled
                                        name='inner' 
                                        value={modal.inner} 
                                        onIonChange={(e) => onModalFieldChange(e.target.name, e.target.value)} 
                                    ></IonInput>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Qty to Send</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput disabled name='qty_to_send' value={modal.qty_to_send} onIonChange={(e) => {onModalFieldChange(e.target.name, e.target.value) }}></IonInput>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Remaning Qty</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput disabled name='remaining_qty' value={modal.remaining_qty} onIonChange={(e) => {onModalFieldChange(e.target.name, e.target.value) }}></IonInput>
                                </IonCol>
                            </IonRow>

                            {/* Conditionally render fields based on sendingInner state */}
                            {sendingInner ? (
                                <>
                                    {/* <IonRow>
                                        <IonCol>
                                            <IonLabel>Inners To Send</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonInput 
                                                disabled 
                                                name='inners_send' 
                                                value={modal.inners_send} 
                                                onIonChange={(e) => {onModalFieldChange(e.target.name, e.target.value) }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Remaining Inners</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonInput 
                                                disabled 
                                                name='remaining_inners' 
                                                value={modal.remaining_inners} 
                                                onIonChange={(e) => {onModalFieldChange(e.target.name, e.target.value) }}
                                            />
                                        </IonCol>
                                    </IonRow> */}
                                </>
                            ) : (
                                <>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Cartons To Send</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonInput 
                                                disabled 
                                                name='cartons_send' 
                                                value={modal.cartons_send} 
                                                onIonChange={(e) => {onModalFieldChange(e.target.name, e.target.value) }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Remaining Cartons</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonInput 
                                                disabled 
                                                name='remaining_cartons' 
                                                value={modal.remaining_cartons} 
                                                onIonChange={(e) => {onModalFieldChange(e.target.name, e.target.value) }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}

                            {/* Additional Input Fields */}
                            
                            <IonRow>
                                <IonCol>
                                <IonLabel>{sendingInner ? 'Add Number of Inners' : 'Add Number of Cartons'}</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonInput 
                                        name='quantity' 
                                        value={modal.quantity} 
                                        // onIonChange={(e) => handleFieldChange(e.target.name, e.target.value)} 
                                        onIonChange={(e) => handleFieldChange(e.target.name, e.detail.value)}  // Correctly pass value
                                    />
                                </IonCol>
                                
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Added By</IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel><strong>&nbsp; {userName}</strong></IonLabel>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    <IonRow>
                        <IonCol sizeMd='4'></IonCol>
                        <IonCol sizeMd='4'>
                            {/* <IonButton size='small' onClick={onDoneClick}>Done</IonButton> */}
                            {/* <IonButton size='small' onClick={onDoneClick} disabled={isQuantityExceeded}></IonButton> */}
                            <IonButton
                                    size='small'
                                    onClick={onDoneClick}
                                    disabled={Quantity > modal.oh_quantity}  // Button is disabled if condition is true
                                >
                                    Done
                                </IonButton>
                        </IonCol>
                        <IonCol sizeMd='4'>
                            <IonButton size='small' onClick={closeModal}>Close</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </IonModal>
        <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}  // Close the alert when dismissed
                header={'Alert'}
                message={alertMessage}
                buttons={['OK']}
            />
        </>
    );
};

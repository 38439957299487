export const stringformat = function (a, values) {

    for (let i = 0; i < values.length; i++) {
        a = a.replace("{" + i + "}", values[i])
    }

    return a
}

export const getUtcDateTime = function () {
    return (new Date()).toUTCString();
}

export const getUserStore = function () {

    if (localStorage.getItem('userType') === 'store') {

        let store = localStorage.getItem('store');
        return isNaN(store) ? '' : parseInt(store);
    }

    return '';
}

export const setItemStatusColor = function (value, status) {

    if (value && status && status.toLowerCase() === 'received' && value.received_count > 0 && value.received_variance > 0) {
        return 'variance-status';
    }
}

export const getDayDiff = function (date) {

    if (date instanceof Date && !isNaN(date.valueOf())) {
        let date1 = new Date(date);
        let date2 = new Date();

        // To calculate the time difference of two dates 
        let difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates 
        let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
        return difference_In_Days;
    }
}

export const getVariance = function (qty, rcvd) {

    let _variance = qty && rcvd && !isNaN(qty) && !isNaN(rcvd) ? parseInt(qty) - parseInt(rcvd) : '';
    return _variance;
}

export const convertTZ = function (date, tzString) {

    if (!date)
        return date;

    let _tzDate = new Date(new Date(date + ' +0:00').toLocaleString("en-US", { timeZone: tzString }));

    const _year = _tzDate.getFullYear();

    let _month = _tzDate.getMonth() + 1;
    _month = _month.toString().length == 1 ? "0" + _month.toString() : _month;

    let _date = _tzDate.getDate();
    _date = _date.toString().length == 1 ? "0" + _date.toString() : _date;

    const _formatted = `${_date}/${_month}/${_year} ${formatAMPM(_tzDate)}`
    return _formatted;
}

const formatAMPM = function (date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


export const hasPermission = function (action) {

    let userType = localStorage.getItem('userType');

    if (userType === 'admin')
        return true;

    switch (action) {
        case 'Booking':
            return userType === 'warehouse';
        case 'Dispatch':
            return userType === 'warehouse'
        case 'AddPallet':
            return userType === 'warehouse' || userType === 'buyer'
        case 'UpdatePallet':
            return userType === 'warehouse' || userType === 'store' || userType === 'buyer'
        case 'DeletePallet':
            return userType === 'warehouse' || userType === 'buyer'
        case 'AddPalletItem':
            return userType === 'warehouse'
        case 'UpdatePalletItem':
            return userType === 'warehouse'
        case 'DeletePalletItem':
            return userType === 'warehouse'
        case 'UpdateStatus':
            return userType === 'warehouse'
        case 'PrintLabel':
            return userType === 'warehouse'
        case 'Warehouse':
            return userType === 'warehouse'
        case 'Distribution':
            return userType === 'warehouse'
        case 'ReceiveItem':
            return userType === 'store'
        case 'OrderReport':
            return userType === 'manager' || userType === 'warehouse' || userType === 'buyer'
        case 'Sync':
            return userType === 'warehouse'
        case 'ShipperMaster':
            return userType === 'warehouse'
        case 'PalletTypeMaster':
            return userType === 'warehouse'
        case 'CategoryMaster':
            return userType === 'warehouse'
        // case 'Product':
        //     return userType === 'manager' || userType === 'buyer'
        // case 'PublishProduct':
        //     return userType === 'manager' || userType === 'buyer'
        // case 'UnPublishProduct':
        //     return userType === 'manager' || userType === 'buyer'
        // case 'UpdateProduct':
        //     return userType === 'manager' || userType === 'buyer'
        default:
            return false;
    }
}

export const isValidStatusToChange = function (statusToChange, currentStaus) {

    switch (currentStaus) {
        case "Wrapping":
            switch (statusToChange) {
                case "Wrapping":
                    return true;
                case "On Hold":
                    return true;
                case "Other (See notes)":
                    return true;
                case "Wrapped":
                    return true;
                case "Request To Hold":
                    return true;
                default:
                    return false;
            }
        case "Received":
            switch (statusToChange) {
                case "Received":
                    return true;
                case "Other (See notes)":
                    return true;
                default:
                    return false;
            }
        case "OnHold":
            switch (statusToChange) {
                case "Wrapping":
                    return true;
                case "On Hold":
                    return true;
                case "Other (See notes)":
                    return true;
                case "Wrapped":
                    return true;
                case "Booked":
                    return true;
                case "Request To Dispatch":
                    return true;
                default:
                    return false;
            }
        case "Dispatched":
            switch (statusToChange) {
                case "Received":
                    return true;
                case "Dispatched":
                    return true;
                case "Other (See notes)":
                    return true;
                case "In Depot":
                    return true;
                default:
                    return false;
            }
        case "Other (See notes)":
            switch (statusToChange) {
                case "Wrapping":
                    return true;
                case "Received":
                    return true;
                case "On Hold":
                    return true;
                case "Dispatched":
                    return true;
                case "Other (See notes)":
                    return true;
                case "Wrapped":
                    return true;
                case "Booked":
                    return true;
                case "Request To Hold":
                    return true;
                case "Request To Dispatch":
                    return true;
                case "In Depot":
                    return true;
                default:
                    return false;
            }
        case "Wrapped":
            switch (statusToChange) {
                case "Wrapping":
                    return true;
                case "On Hold":
                    return true;
                case "Other (See notes)":
                    return true;
                case "Wrapped":
                    return true;
                case "Booked":
                    return true;
                case "Request To Hold":
                    return true;
                default:
                    return false;
            }
        case "Booked":
            switch (statusToChange) {
                case "Dispatched":
                    return true;
                case "Other (See notes)":
                    return true;
                case "Wrapped":
                    return true;
                case "Booked":
                    return true;
                default:
                    return false;
            }
        case "Request To Hold":
            switch (statusToChange) {
                case "On Hold":
                    return true;
                case "Other (See notes)":
                    return true;
                case "Request To Hold":
                    return true;
                case "Request To Dispatch":
                    return true;
                default:
                    return false;
            }
        case "Request To Dispatch":
            switch (statusToChange) {
                case "Wrapping":
                    return true;
                case "Other (See notes)":
                    return true;
                case "Wrapped":
                    return true;
                case "Request To Hold":
                    return true;
                case "Request To Dispatch":
                    return true;
                default:
                    return false;
            }
        case "In Depot":
            switch (statusToChange) {
                case "Received":
                    return true;
                case "Dispatched":
                    return true;
                case "Other (See notes)":
                    return true;
                case "In Depot":
                    return true;
                default:
                    return false;
            }
        default:
            return true;
    }
}

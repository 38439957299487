import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import {
    IonPage, IonCard, IonCardContent, IonCardHeader, IonCardTitle,IonItem,
    IonButton, IonInput, IonLabel, IonRow, IonCol, IonTextarea, IonIcon, IonFab, IonActionSheet, IonFabButton, IonSelect, IonSelectOption, IonRadioGroup
  } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import './index.css';
import {
    addUpdatePalletsMasterDataAction, addItemToListAction, unMountAddUpdateData,
    savePalletAction, palletItemsAction, updatePalletAction, receivedPalletItemsAction, updatePalletFormData, 
    getItemDescriptionAction,getDIDdescriptionAction, getITODetailsAction,getITOdescriptionNewAction,getdidnumbervalidAction,getitonumbervalidAction
} from './action';
import { SingleSelect } from '../../components/single-select';
import { webUrl } from '../../shared/constants';
import { PalletItemModal } from '../../components/pallet-item-modal';
import { getUtcDateTime, hasPermission, isValidStatusToChange, getUserStore, getVariance, setItemStatusColor } from '../../shared/common';
import { AppAlert } from '../../components/app-alert';
import { trashOutline, createOutline } from 'ionicons/icons';
import { ReceiveItemModal } from '../../components/receive-item-modal';
import { DirectToStore } from '../../components/direct-to-store';

const AddUpdatePallet = (props) => {

    const [formData, setFormData] = useState({ createdBy: props.userName, modal: {} });
    const [itemDetailsToShowIndex, setItemDetailsToShowIndex] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [apiResponse, setApiResponse] = useState('');
    const [selectedItemIndex, setSelectedItemIndex] = useState('');
    const [palletItemId, setPalletItemId] = useState(0);
    const [showItemConfirm, setShowItemConfirm] = useState(false);
    const [showReceiveModal, setShowReceiveModal] = useState(false);
    const [noOfPallet, setNoOfPallet] = useState('');
    const [supplier, setSupplier] = useState('');
    const [showDirectToStoreModal, setShowDirectToStoreModal] = useState(false);
    
    const [conNumber, setConNumber] = useState('');
    const [selected, setSelected] = useState('Male');
    const [sendingInner, setSendingInner] = useState(false);
    

    const handleDidNumberBlur = async (e) => {
        const didNumber = e.target.value;
        if (didNumber) {
            try {
                // Corrected function name
                const response = await props.getdidnumbervalid(didNumber);    
                // Check if the response is defined and is an array
                if (!response ||  response[0]?.Error) {
                    setAlertMessage('Invalid DID Number entered');
                    setShowAlert(true);
                } else {
                    // Clear ito_Number if did_Number is filled and valid
                    // onModalFieldChange('ito_Number', '');
                    setShowAlert(false); // Hide alert if DID is valid
                }
            } catch (error) {
                console.error('Error validating DID Number:', error);
                setAlertMessage('Error validating DID Number');
                setShowAlert(true);
            }
        }
    };
    
    
    // const handleItoNumberBlur = async (e) => {
    //     const itoNumber = e.target.value;
    //     if (itoNumber) {
    //         try {
    //             // Correct function name here
    //             const response = await props.getitonumbervalid(itoNumber);
    //             if (!response || response[0]?.Error) {
    //                 setAlertMessage('Invalid ITO Number entered');
    //                 setShowAlert(true);
    //             } else {
    //                 onModalFieldChange('did_Number', '');
    //                 setShowAlert(false);
    //             }
    //         } catch (error) {
    //             console.error('Error validating ITO Number:', error);
    //             setAlertMessage('Error validating ITO Number');
    //             setShowAlert(true);
    //         }
    //     }
    // };
    

    
    const handleFieldChange = (name, value) => {
        setFormData(prevFormData => {
            const updatedModal = { ...prevFormData.modal, [name]: value };
                
            // Disable did_Number if ito_Number is filled and vice versa
            // if (name === 'did_Number' && value) {
            //     updatedModal.ito_Number = '';  // Clear ito_Number if did_Number is filled
            // } else if (name === 'ito_Number' && value) {
            //     updatedModal.did_Number = '';  // Clear did_Number if ito_Number is filled
            // }
    
            const quantity = parseInt(updatedModal.quantity) || 0;
            const qtyToSend = parseInt(updatedModal.qty_to_send) || 0;
            const totalCartons = parseInt(updatedModal.cartons_send) || 0;
            const doneCartons = parseInt(updatedModal.done_cartons) || 0;
            const totalInners = parseInt(updatedModal.inners_send) || 0;
            const doneInners = parseInt(updatedModal.done_inners) || 0;
            const pouter = parseInt(updatedModal.outer) || 0; // Make sure to parse the inner and outer values correctly
            const pinner = parseInt(updatedModal.inner) || 0;

          
    
            // Always perform calculations when quantity or sendingInner changes
            if (name === 'quantity' || name=== 'qtyToSend' || name === 'totalCartons' || name === 'pouter' || name=== 'pinner') {
                if (sendingInner) {
                    // if (updatedModal.did_Number || updatedModal.ito_Number) {
                        updatedModal.qty_to_send = qtyToSend ;
                        updatedModal.remaining_qty = (qtyToSend - (quantity * pinner));
                        updatedModal.inners_send = totalInners;
                        updatedModal.remaining_inners = (totalInners - quantity);
                        
                    // }
                } else {
                    // if (updatedModal.did_Number || updatedModal.ito_Number) {
                        updatedModal.qty_to_send = qtyToSend ;
                        updatedModal.remaining_qty = (qtyToSend - (quantity * pouter));
                        updatedModal.cartons_send = totalCartons;
                        updatedModal.remaining_cartons = totalCartons - quantity;
                    // }
                }
            }
    
            return { ...prevFormData, modal: updatedModal };
        });
    };
    
    


    const setInitialData = () => {

        if (props.match.params.id && props.pallets && props.pallets[0]) {

            let palletToUpdate = props.pallets[0].find(x => x.id === parseInt(props.match.params.id));
            //console.log("props.pallet",props.pallets);
            if (palletToUpdate) {
                let _formData = {
                    id: parseInt(props.match.params.id),
                    store: parseInt(palletToUpdate.store_id),
                    category: palletToUpdate.category,
                    palletType: palletToUpdate.pallet_type,
                    weight: palletToUpdate.weight,
                    builtBy: palletToUpdate.built_by,
                    status: palletToUpdate.status,
                    status_btn: palletToUpdate.status_btn,
                    currentStatus: palletToUpdate.status,
                    notes: palletToUpdate.other_notes,
                    Contents: palletToUpdate.Contents,
                    last_status_changed_by: palletToUpdate.last_status_changed_by,
                    last_status_changed_date: palletToUpdate.last_status_changed_date,
                    date_created: palletToUpdate.date_created,
                    wrapped_by: palletToUpdate.wrapped_by,
                    wrapped_date: palletToUpdate.wrapped_date,
                    is_all_item_received: palletToUpdate.is_all_item_received,
                    sent_date: palletToUpdate.sent_date,
                    received_date: palletToUpdate.received_date,
                    in_depot_date: palletToUpdate.in_depot_date,
                    supplier: palletToUpdate.supplier,
                    con_number: palletToUpdate.con_number,
                    modal: {}
                }
                setFormData(_formData)
                props.palletItemsAction(props.match.params.id);
            }
        }
        else {
            props.unMountAddUpdateData();
        }
    }

    useEffect(() => {

        props.addUpdatePalletsMasterDataAction();
        setInitialData();

        return () => {
            props.unMountAddUpdateData();
        }
    }, []);

    const onFieldChange = (name, value) => {

        setFormData({
            ...formData,
            [name]: value
        });
        console.log("form2_filedchange",formData);
    }

    const  onStatusBtnClick = (name, value) => {
        console.log("name,value",name,value);
        setFormData({
            ...formData,
            [name]: value
        });
        setTimeout(() => {
        }, 2000);
        onClickSave();
        console.log("form",formData);
        console.log("props.pallet",props.pallets);
    }

    const getStoreName = (storeId) => {
        const store = props.palletStore.find((store) => store.id === storeId);
        return store ? store.store_name : '';
    };

    const [showDownloadOptions, setShowDownloadOptions] = useState(false);

    const generatePDF = () => {
        const doc = new jsPDF();
      
        // Add pallet information header
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text('Pallet Information', 20, 20);
      
        // Add Pallet ID and Store Name on the same line
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        const idText = `Pallet ID: ${formData.id || 'N/A'}`;
        const storeName = `Store Name: ${getStoreName(formData.store) || 'N/A'}`;
        const maxWidth = doc.internal.pageSize.getWidth() - 40; // Adjust for margins
        const idWidth = doc.getTextWidth(idText);
        doc.text(idText, 20, 40);
        doc.text(storeName, 20 + idWidth + 10, 40); // Add some spacing between ID and store name
      
        // Add rest of the pallet information in two columns
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const leftColumn = 20;
        const rightColumn = 100;
        let currentRow = 50; // Adjusted spacing
      
        doc.text('Pallet Type:', leftColumn, currentRow);
        doc.text(formData.palletType || 'N/A', rightColumn, currentRow);
        currentRow += 8; // Reduced spacing
      
        doc.text('Weight:', leftColumn, currentRow);
        doc.text(formData.weight || 'N/A', rightColumn, currentRow);
        currentRow += 8; // Reduced spacing
      
        doc.text('Last Status Changed By:', leftColumn, currentRow);
        doc.text(formData.last_status_changed_by || 'N/A', rightColumn, currentRow);
        currentRow += 8; // Reduced spacing
      
        doc.text('Contents:', leftColumn, currentRow);
        const contentLines = doc.splitTextToSize(formData.Contents || 'N/A', 180);
        doc.text(contentLines, rightColumn, currentRow);
        currentRow += 8 * contentLines.length; // Reduced spacing
      
        // Add items table
        const tableData = props.palletItems.map((item) => [
          item.id,
          item.ito,
        // item.did_Number,
          item.barcode,
          item.description,
          item.quantity,
          item.inner,
          item.outer,
        ]);
      
        const tableHeaders = ['Item ID', 'ITO', 'Barcode','Description', 'Quantity', 'Inner', 'Outer'];
      
        doc.autoTable({
          head: [tableHeaders],
          body: tableData,
          startY: currentRow + 5, // Reduced spacing
        });
      
        // Save the PDF file with dynamic pallet ID in the file name
        doc.save(`pick_list_${formData.id || 'N/A'}.pdf`);
    };

    const generateExcel = () => {
        const palletInfoData = [
            ["Pallet ID", formData.id || 'N/A'],
            ["Store Name", getStoreName(formData.store) || 'N/A'],
            ["Pallet Type", formData.palletType || 'N/A'],
            ["Weight", formData.weight || 'N/A'],
            ["Last Status Changed By", formData.last_status_changed_by || 'N/A'],
            ["Contents", formData.Contents || 'N/A'],
        ];
    
        const itemDetailsData = [
            ["Item ID", "ITO Number", "Barcode", "Description", "Quantity", "Inner", "Outer"],
            ...props.palletItems.map(item => [item.id, item.ito, item.barcode, item.description, item.quantity, item.inner, item.outer])
        ];
    
        // Create workbook
        const wb = XLSX.utils.book_new();
    
        // Pallet Information Sheet
        const ws1 = XLSX.utils.aoa_to_sheet(palletInfoData);
        
        // Beautify Pallet Information Sheet
        const ws1cols = [{wch: 20}, {wch: 30}]; // Set column widths
        ws1['!cols'] = ws1cols;
        ws1['!rows'] = palletInfoData.map(() => ({ hpx: 20 })); // Set row heights
        XLSX.utils.book_append_sheet(wb, ws1, "Pallet Information");
    
        // Item Details Sheet
        const ws2 = XLSX.utils.aoa_to_sheet(itemDetailsData);
        
        // Beautify Item Details Sheet
        const ws2cols = itemDetailsData[0].map(() => ({wch: 15})); // Set uniform column widths
        ws2['!cols'] = ws2cols;
        ws2['!rows'] = [{ hpx: 20 }, ...itemDetailsData.slice(1).map(() => ({ hpx: 16 }))]; // Set row heights with header row taller
        XLSX.utils.book_append_sheet(wb, ws2, "Item Details");
    
        // Generate Excel file and save with dynamic pallet ID in the file name
        XLSX.writeFile(wb, `pick_list_${formData.id || 'N/A'}.xlsx`);
    };
    
    
      

    const onModalFieldChange = (name, value) => {

        let _formData = formData;
        _formData.modal[name] = value;
        setFormData({ ..._formData });
    }

    const onItemRowClick = (index) => {
        index === itemDetailsToShowIndex
            ? setItemDetailsToShowIndex(undefined) :
            setItemDetailsToShowIndex(index);
    }

    const onDoneClick = () => {
        let ito = formData.modal.did_Number;
        // let ito = formData.modal.did_Number || formData.modal.ito_Number;
        // let adjustedQuantity = sendingInner 
        // ? formData.modal.quantity * formData.modal.inner 
        // : formData.modal.quantity * formData.modal.outer;
        let itemData = {
            id: formData.modal.id,
            // quantity: adjustedQuantity,
            quantity: formData.modal.quantity,
            // did_Number: formData.modal.did_Number,
            ito: ito,
            // ito_Number : formData.modal.ito_Number,
            qty_to_send : formData.modal.qty_to_send,
            remaining_qty: formData.modal.remaining_qty,
            inners_send : formData.modal.inners_send,
            remaining_inners: formData.modal.remaining_inners,
            cartons_send : formData.modal.cartons_send,
            remaining_cartons : formData.modal.remaining_cartons,



            barcode: formData.modal.barcode,
            outer: formData.modal.outer,
            description: formData.modal.description,
            inner: formData.modal.inner,

            oh_quantity: formData.modal.oh_quantity,

            added_by: formData.modal.id > 0 ? formData.modal.addedBy : props.userName,
            added_on: formData.modal.id > 0 ? formData.modal.addedOn : getUtcDateTime(),
            pallet_id: formData.id,
            received_by: formData.modal.receivedBy,
            received_count: formData.modal.receivedCount,
            received_variance: formData.modal.variance,
            sendingInner: sendingInner,
        }

        if (itemData.id && itemData.id > 0) {
            let _palletItems = props.palletItems;

            _palletItems.forEach((element, elementIndex) => {

                if (element.id === itemData.id) {
                    _palletItems[elementIndex] = itemData;
                }
            });

            props.updatePalletItemsAction(_palletItems);
        }
        else if (selectedItemIndex === 0 || selectedItemIndex > 0) {
            let _palletItems = props.palletItems;

            _palletItems.forEach((element, elementIndex) => {

                if (elementIndex === selectedItemIndex) {
                    _palletItems[elementIndex] = itemData;
                }
            });

            props.updatePalletItemsAction(_palletItems);
        }
        else {
            props.addItemToListAction(itemData);
        }

        //closeModal();
        setSelectedItemIndex(undefined);
        setFormData({
            ...formData,
            modal: {}
        });
    }

    const closeModal = () => {
        setShowModal(false);
        setFormData({
            ...formData,
            modal: {}
        });
        setItemDetailsToShowIndex(undefined);
        setShowReceiveModal(false);
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setSelected(event.target.value);
    };

    const onClickSave = () => {

        if (formData.id && formData.id > 0 && !isValidStatusToChange(formData.status, formData.currentStatus)) {

            setAlertMessage('You can not change status from "' + formData.currentStatus + '" to "' + formData.status + '"')
            setShowAlert(true);
            return;
        }
           
        let palletData = {
            id: formData.id,
            store_id: formData.store,
            category: formData.category,
            pallet_type: formData.palletType,
            weight: formData.weight,
            built_by: formData.builtBy,
            //status: formData.id ?formData.status : 'Wrapping',
            status: formData.status, //?formData.status : 'Wrapping'
            other_notes: formData.notes,
            contents: formData.Contents,
            last_status_changed_by: props.userName,
            last_status_changed_date: getUtcDateTime(),
            date_created: formData.id ? formData.date_created : getUtcDateTime(),
            palletItems: props.palletItems || undefined,
            supplier: formData.id ? formData.supplier : supplier,
            con_number: formData.con_number
        }
        {console.log('formdata',formData.Contents);}
        {console.log('palletdata',palletData);}
        

        if (formData.status && formData.status.toLowerCase() === 'wrapped') {
            palletData.wrapped_by = props.userName;
            palletData.wrapped_date = getUtcDateTime();
        }
        /* else {
            palletData.wrapped_by = formData.wrapped_by;
            palletData.wrapped_date = formData.wrapped_date;
        } */

        if (formData.status && formData.status.toLowerCase() === 'in depot') {
            palletData.in_depot_date = getUtcDateTime();
        }
        else {
            palletData.in_depot_date = formData.in_depot_date;
        }

        if (formData.status && formData.status.toLowerCase() === 'dispatched') {
            palletData.sent_date = getUtcDateTime();
        }
        else {
            palletData.sent_date = formData.sent_date;
        }

        if (formData.status && formData.status.toLowerCase() === 'received') {
            //palletData.wrapped_by = props.userName;
            palletData.received_date = getUtcDateTime();
        }
        // else {
        //     palletData.received_date = formData.received_date;
        // }

        if (formData.id) {

            if (formData.status && formData.status.toLowerCase() === 'received') {
                var notReceivedItems = palletData.palletItems.filter(x => x.received_count && x.received_count > 0 && x.received_variance && x.received_variance > 0);
                palletData.is_all_item_received = (notReceivedItems.length <= 0) ? 1 : 0;
            }

            props.updatePalletAction(palletData).then((response) => {
                setApiResponse(response);
                response ?
                    setAlertMessage("Updated successfully!!!") :
                    setAlertMessage("Updation failed!!!");
                setShowAlert(true);
                response && props.updateFormData({ callApi: true });
            });
        }
        else if (noOfPallet) {

            console.log(noOfPallet);
            const promises = [];
            palletData.status = 'Dispatched';
            palletData.con_number = conNumber;

            for (let i = 0; i < parseInt(noOfPallet); i++) {
                promises.push(props.savePalletAction(palletData));
            }

            // *** 3
            return Promise.all(promises)
                .then((response) => {
                    setApiResponse(response);
                    response && setAlertMessage("Saved successfully!!!");
                    setShowAlert(true);
                    response && props.updateFormData({ callApi: true });
                    setShowDirectToStoreModal(false);
                    setNoOfPallet('');
                    setSupplier('');
                    setConNumber('');
                })
                .catch(err => {
                    setShowAlert(true);
                    setAlertMessage("Saved failed!!!");
                });
        }
        else {
            props.savePalletAction(palletData).then((response) => {
                setApiResponse(response);
                response ?
                    setAlertMessage("Saved successfully!!!") :
                    setAlertMessage("Saved failed!!!");
                setShowAlert(true);
                response && props.updateFormData({ callApi: true });
            });
        }
    }

    const onItemEdit = (data) => {
        setShowModal(true);
        setPalletItemId(data.id);
        // let did_Number = data.ito && data.ito.startsWith('000') ? data.ito : '';
        // let ito_Number = data.ito && !data.ito.startsWith('0000') ? data.ito : '';       

        // let originalQuantity;
        // if (data.sendingInner === true || data.sendingInner === 'True') {
        //     originalQuantity = data.quantity / data.inner;
        // } else {
        //     originalQuantity = data.quantity / data.outer;
        // }
       
        setFormData({
            ...formData,
            modal: {
                id: data.id,
                // quantity: originalQuantity,
                quantity: data.quantity,
                did_Number: data.ito,
                // ito_Number: ito_Number,
                barcode: data.barcode,
                outer: data.outer,
                description: data.description,
                inner: data.inner,
                addedBy: data.added_by,
                addedOn: data.added_on,
                palletId: data.pallet_id,
                index: data.index,
                receivedCount: data.received_count,
                variance: data.received_variance,
                receivedBy: data.received_by,
                oh_quantity: data.oh_quantity,
                
            qty_to_send : data.qty_to_send,
            remaining_qty: data.remaining_qty,
            inners_send : data.inners_send,
            remaining_inners: data.remaining_inners,
            cartons_send : data.cartons_send,
            remaining_cartons : data.remaining_cartons, // Use stored value if exists
            sendingInner: data.sendingInner,

            }
        });
        setSendingInner(data.sendingInner === true || data.sendingInner === 'True');
    }

    const onITOBlur = (e) => {
        if (e && e.target && e.target.value) {
            props.getITODetails(e.target.value).then((response) => {

                if (response && response[0]) {
                    onModalFieldChange('description', response[0].description);
                }
            });
        }
    }


    
    const onBarcodeBlur = async (e) => {
        if (e && e.target && e.target.value) {
            const { modal } = formData;
            const barcode = e.target.value;
          
    
            try {
                let didDescriptionResponse, itoDescriptionResponse;
                const quantity = parseInt(modal.quantity) || 0;
                let pinner = 0;
                let pouter = 0;
              
    
    
                // Use await to wait for the API call to complete
                const response = await props.getItemDescription(barcode);
                if (response || response[0]) {
                    pouter = response.Carton_Qty || 0;
                    pinner = Math.round(response.custom3) || 0;
                    onModalFieldChange('description', response.Short_Description || '');
                    onModalFieldChange('outer', pouter);
                    onModalFieldChange('inner', pinner);
                    onModalFieldChange('oh_quantity', response.ohquantity || 0);
                }
    
                // Conditionally call APIs based on filled fields
                if (modal.did_Number) {
                    didDescriptionResponse = await props.getDIDdescription(modal.did_Number, barcode);
                } 
                // else if (modal.ito_Number) {
                //     itoDescriptionResponse = await props.getITOdescriptionNew(modal.ito_Number, barcode);
                // }
    
                // Handle DID Description Response
                if (didDescriptionResponse && didDescriptionResponse[0]) {
                    const { qty_to_send, total_cartons, done_cartons, total_inners } = didDescriptionResponse[0];;
                    if (sendingInner) {
                        onModalFieldChange('qty_to_send', qty_to_send);
                        onModalFieldChange('remaining_qty', ((qty_to_send - (quantity * pinner))));
                        onModalFieldChange('inners_send', total_inners);
                        onModalFieldChange('remaining_inners', (total_inners - quantity));
                        
                    } else {
                        onModalFieldChange('qty_to_send', qty_to_send);
                        onModalFieldChange('remaining_qty', ((qty_to_send - (quantity * pouter))));
                        onModalFieldChange('cartons_send', total_cartons);
                        onModalFieldChange('remaining_cartons', (total_cartons - done_cartons));
                    }
                }
    
                // Handle ITO Description Response
                // if (itoDescriptionResponse && itoDescriptionResponse[0]) {
                //     const { total_carton, total_qty, total_inner, done_qty, qty_to_send } = itoDescriptionResponse[0];
                //     if (sendingInner) {
                //         onModalFieldChange('qty_to_send', qty_to_send);
                //         onModalFieldChange('remaining_qty', qty_to_send - (quantity*pinner));
                //         onModalFieldChange('inners_send', total_inner);
                //         onModalFieldChange('remaining_inners', total_inner - quantity);
                //     } else {
                //         onModalFieldChange('qty_to_send', qty_to_send);
                //         onModalFieldChange('remaining_qty', qty_to_send - (quantity*pouter));
                //         onModalFieldChange('cartons_send', total_carton);
                //         onModalFieldChange('remaining_cartons', total_carton - done_qty);
                //     }
                // }
    
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    

    const onReceiveEdit = (data) => {
        setShowReceiveModal(true);
        setPalletItemId(data.id);
        // console.log("eeeeeeeeeeeeeeeee",data)
        // let Quantity;
        // if (data.sendingInner === 'True') {
        //     Quantity = data.quantity / data.inner;
        // } else {
        //     Quantity = data.quantity / data.outer;
        // }

        setFormData({
            ...formData,
            modal: {
                id: data.id,
                quantity: data.quantity,
                // quantity:Quantity,
                receivedCount: data.received_count,
                variance: data.received_variance,
                palletId: data.pallet_id,
                index: data.index
            }
        })
    }

    const onReceivedClick = () => {

        if (formData.modal.id && formData.modal.id > 0) {
            let _palletItems = props.palletItems;

            _palletItems.forEach((element, elementIndex) => {

                if (element.id === formData.modal.id) {
                    _palletItems[elementIndex].received_count = formData.modal.receivedCount;
                    _palletItems[elementIndex].received_variance = getVariance(formData.modal.quantity, formData.modal.receivedCount);
                    _palletItems[elementIndex].received_by = props.userName;
                }
            });

            props.updatePalletItemsAction(_palletItems);
        }
        else if (selectedItemIndex === 0 || selectedItemIndex > 0) {
            let _palletItems = props.palletItems;

            _palletItems.forEach((element, elementIndex) => {

                if (elementIndex === selectedItemIndex) {
                    _palletItems[elementIndex].received_count = formData.modal.receivedCount;
                    _palletItems[elementIndex].received_variance = getVariance(formData.modal.quantity, formData.modal.receivedCount);
                    _palletItems[elementIndex].received_by = props.userName;
                }
            });

            props.updatePalletItemsAction(_palletItems);
        }

        closeModal();
        setSelectedItemIndex(undefined);
    }

    const onDeletePalletItem = () => {

        let _palletItems;

        if (palletItemId && palletItemId > 0)
            _palletItems = props.palletItems.filter(item => item.id !== palletItemId);
        else {
            _palletItems = props.palletItems.filter((item, index) => index !== selectedItemIndex);
        }
        props.updatePalletItemsAction(_palletItems);
        setShowItemConfirm(false);
        setPalletItemId(0);
        setItemDetailsToShowIndex(undefined);
        setSelectedItemIndex(undefined);
    }

    const onDirectToStoreDoneClick = () => {

        onClickSave();
    }

    const palletItemsData = () => {
        return (

            props.palletItems && props.palletItems.length > 0 ?
                props.palletItems.map((value, index) => {

                    return (
                        <IonCard key={index} onClick={(e) => { e.stopPropagation(); onItemRowClick(index) }}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol sizeMd='2'>
                                        <IonLabel className='head-label'><strong>{value.id || 'New'}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol sizeMd='2'>
                                        <IonLabel className='head-label-small'> Quantity <strong>{value.quantity}</strong></IonLabel>
                                    </IonCol>
                                    {/* <IonCol sizeMd='2'>
                                        <IonLabel className='head-label-small'> Qty Inner <strong>{value.qty_inner}</strong></IonLabel>
                                    </IonCol> */}
                                    {
                                        setItemStatusColor(value, formData.currentStatus) &&
                                        <IonCol size='2'>
                                            <IonFab><IonFabButton disabled className={'status-fab ' + setItemStatusColor(value, formData.currentStatus)}></IonFabButton></IonFab>
                                        </IonCol>
                                    }

                                    <IonCol className='card-action'>
                                        {

                                            hasPermission('DeletePalletItem') &&
                                            <IonIcon color='danger' onClick={(e) => {
                                                e.stopPropagation();
                                                setShowItemConfirm(true);
                                                setAlertMessage("Are you sure to delete?");
                                                setPalletItemId(value.id);
                                                setSelectedItemIndex(index);
                                            }} icon={trashOutline} className='header-icon' />
                                        }

                                        {
                                            hasPermission('UpdatePalletItem') &&
                                            <IonIcon onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedItemIndex(index);
                                                let data = value;
                                                data.index = index;
                                                onItemEdit(value);
                                            }} icon={createOutline} className='header-icon' />
                                        }

                                        {
                                            hasPermission('ReceiveItem') && formData &&
                                            (formData.currentStatus === 'Dispatched' || formData.currentStatus === 'Received')
                                            && < IonIcon onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedItemIndex(index);
                                                let data = value;
                                                data.index = index;
                                                onReceiveEdit(value);
                                            }} icon={createOutline} className='header-icon' />
                                        }


                                    </IonCol>
                                </IonRow>
                                {
                                    index === itemDetailsToShowIndex &&
                                    <>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> DID no. <strong>{value.did_Number}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            {/* <IonCol>
                                                <IonLabel> ITO no. <strong>{value.ito_Number}</strong></IonLabel>
                                            </IonCol> */}
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Barcode <strong>{value.barcode}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Quantity<strong>{value.quantity}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>


                                        {/* <IonRow>
                                            <IonCol>
                                                <IonLabel> Quantity Inner  <strong>{value.qty_inner}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow> */}



                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Description  <strong>{value.description}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Outer <strong>{value.outer}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Inner <strong>{value.inner}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Received <strong>{value.received_count}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Added by <strong>{value.added_by}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </>

                                }
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText={formData.id ? ('Update Pallet (' + formData.id + ')') : 'Add Pallet'} redirectTo={props.history.push} />

            <div className="pallet-body">
                <IonCard className='add-update-pallet add-update-pallet-content'>
                    <IonCardContent className='search-field-section'>
                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonLabel> Store</IonLabel>
                                <SingleSelect
                                    name='store'
                                    options={props.palletStore}
                                    optionValue='id'
                                    optionName='store_name'
                                    onChange={onFieldChange}
                                    value={formData.store}
                                    disabled={!!props.userStore} />
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Category</IonLabel>
                                <SingleSelect
                                    name='category'
                                    options={props.palletCategory}
                                    optionValue='category_name'
                                    optionName='category_name'
                                    onChange={onFieldChange}
                                    value={formData.category}
                                    disabled={!!props.userStore} />
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Pallet Type</IonLabel>
                                <SingleSelect
                                    name='palletType'
                                    options={props.palletTypes}
                                    optionValue='type'
                                    optionName='type'
                                    onChange={onFieldChange}
                                    value={formData.palletType}
                                    disabled={!!props.userStore} />
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonLabel> Weight</IonLabel>
                                <IonInput name='weight'
                                    value={formData.weight}
                                    onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}
                                    disabled={!!props.userStore}>
                                    </IonInput>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Built By</IonLabel>
                                <SingleSelect
                                    name='builtBy'
                                    options={props.palletBuilders}
                                    optionValue='builder_name'
                                    optionName='builder_name'
                                    onChange={onFieldChange}
                                    value={formData.builtBy}
                                    disabled={!!props.userStore} />
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Created By</IonLabel>
                                <IonLabel> <strong>&nbsp;&nbsp;{formData.createdBy}</strong></IonLabel>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                    <IonCardContent className='search-field-section'>
                        <IonRow>
                        {/* {formData.id ? "" : "3"} */}
                            <IonCol sizeMd={formData.id ? "" : "3"}>
                                <IonLabel > Contents</IonLabel>
                                <IonTextarea
                                    name='Contents'
                                    value={formData.Contents}
                                    onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}
                                    disabled={!!props.userStore}>
                                    </IonTextarea>
                            </IonCol>
                            <IonCol sizeMd={formData.id ? "" : "3"}>
                                <IonLabel> Other Notes</IonLabel>
                                <IonTextarea name='notes' value={formData.notes} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonTextarea>
                            </IonCol>
                            
                            {/* <IonRadioGroup value={formData.status = 'Wrapped'}>
                            <IonCol sizeMd='2'>
                                    <IonItem>
                                        <IonLabel>Wrapped</IonLabel>
                                        {console.log("pallet",formData.store)}
                                        <IonRadio slot="start" value={formData.status = 'Wrapped'} onChange={onFieldChange}></IonRadio>
                                    </IonItem>
                            </IonCol>
                            <IonCol sizeMd='2'>
                                    <IonItem>
                                        <IonLabel>Wrapping</IonLabel>
                                        <IonRadio slot="start" value={formData.status = 'Wrapping'} onChange={onFieldChange}></IonRadio>
                                    </IonItem>
                            </IonCol>
                            <IonCol sizeMd='2'>
                                    <IonItem>
                                        <IonLabel>on Hold</IonLabel>
                                        <IonRadio slot="start" value={formData.status = 'On Hold'} onChange={onFieldChange}></IonRadio>
                                    </IonItem>
                            </IonCol>
                            // </IonRadioGroup> */}
                        </IonRow>
                        {formData.id && <IonRow>
                            <IonCol>
                                <IonLabel> Status</IonLabel>
                                <SingleSelect
                                    name='status'
                                    options={props.palletStatus}
                                    optionValue={(localStorage.getItem('userType') === 'manager' || localStorage.getItem('userType') === 'admin' || localStorage.getItem('userType') === 'buyer') ? 'status_name' : 'status'}
                                    optionName={(localStorage.getItem('userType') === 'manager' || localStorage.getItem('userType') === 'admin' || localStorage.getItem('userType') === 'buyer') ? 'status_name' : 'status'}
                                    onChange={onFieldChange}
                                    value={formData.status} />
                            </IonCol>
                            <IonCol >
                                <IonLabel> Last Status Changed By</IonLabel><br></br>
                                <IonLabel> <strong> &nbsp;{formData.last_status_changed_by}</strong></IonLabel>
                            </IonCol>
                            <IonCol >
                                <IonLabel> Last Status Changed Date</IonLabel><br></br>
                                <IonLabel> <strong> &nbsp;{formData.last_status_changed_date}</strong></IonLabel>
                            </IonCol>

                        </IonRow>
                        }
                        {
                            !formData.id && <IonRow>
                                <IonCol>
                                  <IonItem>
                                    <IonLabel>Status</IonLabel>
                                    <IonSelect name="status" placeholder="Select One" onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}>
                                    <IonSelectOption value="Wrapped">Wrapped</IonSelectOption>
                                    <IonSelectOption value="on Hold">on Hold</IonSelectOption>
                                    </IonSelect>
                                  </IonItem>  
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                    <IonCardContent>
                        <IonRow>
                            {
                                hasPermission('AddPalletItem') &&
                                <IonCol sizeMd='2'>
                                    <IonButton className='button action-btn' type='button' onClick={() => { setShowModal(true) }}>
                                        Add Item
                                    </IonButton>
                                </IonCol>
                            }

                            <IonCol sizeMd='2'>
                                <IonButton className='button action-btn' type='button' onClick={onClickSave}>
                                    Save
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd='2'>
                                <IonButton className='button action-btn' type='button' onClick={() => { props.history.push(webUrl.pallet) }}>
                                    Back
                                </IonButton>
                            </IonCol>
                            <IonCol sizeMd='2'>
                                <IonButton className='button action-btn' type='button' onClick={() => setShowDownloadOptions(true)}>
                                    Download Pick List
                                </IonButton>
                            </IonCol>
                            <IonActionSheet
                                isOpen={showDownloadOptions}
                                onDidDismiss={() => setShowDownloadOptions(false)}
                                buttons={[
                                    {
                                    text: 'Download as PDF',
                                    handler: () => {
                                        generatePDF();
                                    },
                                    },
                                    {
                                    text: 'Download as Excel',
                                    handler: () => {
                                        generateExcel();
                                    },
                                    },
                                    {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    handler: () => {
                                        console.log('Cancel clicked');
                                    },
                                    },
                                ]}
                                ></IonActionSheet>

                            {/* <IonCol sizeMd='2'>
                                <IonButton className='button action-btn' name = 'status_btn' type='button'  value='wrapped' onClick={onStatusBtnClick} >
                                    Wrapped
                                </IonButton>
                            </IonCol>
                            {/* onClick={(e) => { onStatusBtnClick(e.target.name, e.target.value) }} */}
                            {/* <IonCol sizeMd='2'>
                                <IonButton className='button action-btn'  name = 'status_btn' type='button' value= 'on hold' onClick={onClickSave}>
                                    On Hold
                                </IonButton>
                            </IonCol> */} 
                            {
                                formData.id ||
                                <IonCol sizeMd='3'>
                                    <IonButton className='button action-btn' type='button' onClick={() => { setShowDirectToStoreModal(true) }}>
                                        Add Direct To Store
                                     </IonButton>
                                </IonCol>
                            }

                        </IonRow>
                    </IonCardContent>

                </IonCard>

                <IonCard className='add-update-pallet-content pallet-items'>
                    <IonCardHeader className='add-update-pallet-header'>
                        <IonCardTitle> Items</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className='search-result-data'>
                        {palletItemsData()}
                    </IonCardContent>
                </IonCard>
                <PalletItemModal modal={formData.modal} showModal={showModal} onBarcodeBlur={onBarcodeBlur}
                 onModalFieldChange={onModalFieldChange}
                //  onModalFieldChange={(name, value) => handleFieldChange(name, value)}
                //   userName={props.userName} 
                userName={formData.modal.id && formData.modal.id > 0 ? formData.modal.addedBy : props.userName}
                  onDoneClick={onDoneClick} closeModal={closeModal}
                 handleFieldChange={handleFieldChange} 
                 sendingInner={sendingInner}  
                 setSendingInner={setSendingInner} 
                 handleDidNumberBlur={handleDidNumberBlur} // Pass the DID Number blur handler
                //  handleItoNumberBlur={handleItoNumberBlur} 
                 />
                <ReceiveItemModal modal={formData.modal} showModal={showReceiveModal} onModalFieldChange={onModalFieldChange} onDoneClick={onReceivedClick} closeModal={closeModal} />
                <AppAlert showAlert={showAlert} headerText='Message' message={alertMessage} btnCancelText="OK" cancelClick={() => { setShowAlert(false); apiResponse && props.history.push(webUrl.pallet) }} />
                <AppAlert showAlert={showItemConfirm} headerText='Confirm' message={alertMessage} btnCancelText="Cancel" btnOkText="Yes" okClick={onDeletePalletItem} cancelClick={() => { setShowItemConfirm(false); setPalletItemId(0); }} />
                <DirectToStore showModal={showDirectToStoreModal}
                    setNoOfPallet={setNoOfPallet} noOfPallet={noOfPallet}
                    supplier={supplier} setSupplier={setSupplier}
                    conNumber={conNumber} setConNumber={setConNumber}
                    onDoneClick={onDirectToStoreDoneClick} closeModal={() => {
                        setShowDirectToStoreModal(false);
                        setNoOfPallet('');
                        setSupplier('');
                        setConNumber('');
                    }} />

            </div>
        </IonPage >
    );
};

const mapStateToProps = state => ({
    palletStatus: state.pallet.palletStatus,
    palletStore: state.pallet.palletStore,
    palletCategory: state.pallet.palletCategory,
    palletTypes: state.pallet.palletTypes,
    palletBuilders: state.pallet.palletBuilders,
    userName: localStorage.getItem('userName'),
    pallets: state.pallet.pallets,
    palletItems: state.pallet.palletItems,
    userStore: getUserStore()
});

const mapDispatchToProps = dispatch => ({
    addUpdatePalletsMasterDataAction: () => dispatch(addUpdatePalletsMasterDataAction()),
    addItemToListAction: (data) => dispatch(addItemToListAction(data)),
    unMountAddUpdateData: () => dispatch(unMountAddUpdateData()),
    savePalletAction: (data) => dispatch(savePalletAction(data)),
    palletItemsAction: (id) => dispatch(palletItemsAction(id)),
    updatePalletAction: (data) => dispatch(updatePalletAction(data)),
    updatePalletItemsAction: (data) => dispatch(receivedPalletItemsAction(data)),
    updateFormData: (data) => dispatch(updatePalletFormData(data)),
    getItemDescription: (data) => dispatch(getItemDescriptionAction(data)),
    getDIDdescription: (did_reference, barcode) => dispatch(getDIDdescriptionAction(did_reference, barcode)),
    getITOdescriptionNew: (ito_reference, barcode) => dispatch(getITOdescriptionNewAction(ito_reference, barcode)),

    getdidnumbervalid: (did_number) => dispatch(getdidnumbervalidAction(did_number)),
    getitonumbervalid: (ito_number) => dispatch(getitonumbervalidAction(ito_number)),

    getITODetails: (data) => dispatch(getITODetailsAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUpdatePallet));

import {
    palletStatus, pallets, palletStore, palletCategory,
    palletItemsById, palletTypes, palletBuilders, savePallet,
    updatePallet, savePalletItem, deletePallet, deletePalletItem,
    updatePalletItem, palletShipper, getItemDescription, getDIDdescription,getITOdescription_new,getdidnumbervalid,getitonumbervalid,
    syncPrice, syncPriceStatus, getITODetails, updateOdooPalletData
} from './service';
import { showLoaderAction, stopLoaderAction } from '../user/action';

/**
 * Action types
 */
export const actionTypes = {
    'PALLET_STATUS_RECEIVED': 'PALLET_STATUS_RECEIVED',
    'PALLET_CATEGORY_RECEIVED': 'PALLET_CATEGORY_RECEIVED',
    'PALLET_STORE_RECEIVED': 'PALLET_STORE_RECEIVED',
    'PALLETS_RECEIVED': 'PALLETS_RECEIVED',
    'PALLETS_UNMOUNT': 'PALLETS_UNMOUNT',
    'PALLETS_CLEAR': 'PALLETS_CLEAR',
    'PALLET_ITEMS_RECEIVED': 'PALLET_ITEMS_RECEIVED',
    'PALLET_BUILDERS_RECEIVED': 'PALLET_BUILDERS_RECEIVED',
    'PALLET_TYPES_RECEIVED': 'PALLET_TYPES_RECEIVED',
    'PALLET_ADD_ITEM_TO_LIST': 'PALLET_ADD_ITEM_TO_LIST',
    'PALLET_ADD_UPDATE_UNMOUNT': 'PALLET_ADD_UPDATE_UNMOUNT',
    'PALLET_SHIPPER_RECEIVED': 'PALLET_SHIPPER_RECEIVED',
    'CLEAR_PALLET_FORM_DATA': 'CLEAR_PALLET_FORM_DATA',
    'PALLET_FORM_DATA': 'PALLET_FORM_DATA',
    'PRICE_SYNC_STATUS': 'PRICE_SYNC_STATUS'
};

export const receivedPalletStatusAction = (payload) => ({
    type: actionTypes.PALLET_STATUS_RECEIVED,
    payload
});

export const palletStatusAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletStatus'));

        return palletStatus()
            .then((response) => {
                dispatch(stopLoaderAction('palletStatus'));
                dispatch(receivedPalletStatusAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletStatus'));
            });
    }
}

export const receivedPalletStoreAction = (payload) => ({
    type: actionTypes.PALLET_STORE_RECEIVED,
    payload
});

export const palletStoreAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletStore'));

        return palletStore()
            .then((response) => {
                dispatch(stopLoaderAction('palletStore'));
                dispatch(receivedPalletStoreAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletStore'));
            });
    }
}

export const receivedPalletCategoryAction = (payload) => ({
    type: actionTypes.PALLET_CATEGORY_RECEIVED,
    payload
});

export const palletCategoryAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletCategory'));

        return palletCategory()
            .then((response) => {
                dispatch(stopLoaderAction('palletCategory'));
                dispatch(receivedPalletCategoryAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletCategory'));
            });
    }
}

export const receivedPalletsAction = (payload) => ({
    type: actionTypes.PALLETS_RECEIVED,
    payload
});

export const palletsAction = (data) => {

    return (dispatch, getState) => {

        data && data.page < 2 && dispatch(showLoaderAction('pallets'));

        return pallets(data)
            .then((response) => {

                data && data.page < 2 && dispatch(stopLoaderAction('pallets'));

                if (data && data.page > 1) {
                    let state = Object.assign({}, getState());
                    response[0] = [...state.pallet.pallets[0], ...response[0]];
                }

                dispatch(receivedPalletsAction(response));
                return true;
            })
            .catch((error) => {

                data && data.page < 2 && dispatch(stopLoaderAction('pallets'));
                return false;
            });
    }
}

export const unmountPalletsAction = () => ({
    type: actionTypes.PALLETS_UNMOUNT
});

export const palletsMasterDataAction = () => {
    return (dispatch) => {
        dispatch(palletStoreAction());
        dispatch(palletCategoryAction());
        dispatch(palletStatusAction());
    }
};

export const clearPalletsAction = () => ({
    type: actionTypes.PALLETS_CLEAR
});


export const receivedPalletItemsAction = (payload) => ({
    type: actionTypes.PALLET_ITEMS_RECEIVED,
    payload
});

export const palletItemsAction = (id) => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletItems'));

        return palletItemsById(id)
            .then((response) => {

                dispatch(stopLoaderAction('palletItems'));
                dispatch(receivedPalletItemsAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletItems'));
            });
    }
}

export const receivedPalletTypesAction = (payload) => ({
    type: actionTypes.PALLET_TYPES_RECEIVED,
    payload
});

export const palletTypesAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletTypes'));

        return palletTypes()
            .then((response) => {

                dispatch(stopLoaderAction('palletTypes'));
                dispatch(receivedPalletTypesAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletTypes'));
            });
    }
}

export const receivedPalletBuildersAction = (payload) => ({
    type: actionTypes.PALLET_BUILDERS_RECEIVED,
    payload
});

export const palletBuildersAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletBuilders'));

        return palletBuilders()
            .then((response) => {

                dispatch(stopLoaderAction('palletBuilders'));
                dispatch(receivedPalletBuildersAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletBuilders'));
            });
    }
}

export const addUpdatePalletsMasterDataAction = () => {
    return (dispatch) => {
        dispatch(palletTypesAction());
        dispatch(palletBuildersAction());
    }
};

export const addItemToListAction = (payload) => ({
    type: actionTypes.PALLET_ADD_ITEM_TO_LIST,
    payload
});

export const unMountAddUpdateData = () => ({
    type: actionTypes.PALLET_ADD_UPDATE_UNMOUNT
});

// export const savePalletAction = (data) => {

//     return (dispatch) => {

//         dispatch(showLoaderAction('savePallet'));
        
//         return savePallet(data)
//         .then((response) => {
//                 dispatch(stopLoaderAction('savePallet'));
//                 // console.log("Response sav button",response);
//                 // Send pallet data to Odoo
//                 let palletId = response.match(/\d+/)[0];
//                 const palletData = {
//                     pallet_id: palletId,
//                     store_id: data.store_id,
//                     category: data.category,
//                     pallet_type: data.pallet_type,
//                     weight: data.weight,
//                     built_by: data.built_by,
//                     status: data.status,
//                     other_notes: data.other_notes,
//                     contents: data.contents,
//                     last_status_changed_by: data.last_status_changed_by,
//                     last_status_changed_date: data.last_status_changed_date,
//                     date_created: data.date_created,
//                     palletItems: data.palletItems,
//                     supplier: data.supplier,
//                     con_number: data.con_number,
//                 };

//                 {console.log("================================")}
//                 // {console.log("Response",response.json());}
//                 // {console.log("Response ",response.json());}
//                 // {console.log("type Response",typeof(response));}
//                 {console.log(response.match(/\d+/));}
//                 {console.log('pallet data 1',palletData);}
//                 {console.log('data 111111111111111111111',data.palletItems);}
//                 {console.log("================================")}
        
//                 dispatch(sendPalletToOdoo(palletData));
//                 return true;
//             })
//             .catch((error) => {

//                 dispatch(stopLoaderAction('savePallet'));
//                 return false;
//             });
//     }
// }
// export const savePalletAction = (data) => {
//     return (dispatch) => {
//         dispatch(showLoaderAction('savePallet'));
        
//         return savePallet(data)
//             .then((response) => {
//                 dispatch(stopLoaderAction('savePallet'));
//                 console.log(response,"helloooooooooo");
//                 console.log(data);
//                 // Parse the JSON response
//                 const responseData = JSON.parse(response);
               
//                 console.log(responseData,"helloooooooooo");
//                 // Extract pallet ID and pallet item IDs from the response
//                 const palletId = responseData.pallet_id;
//                 const palletItemIds = responseData.pallet_item_ids || [];

//                 console.log(palletId,"palletID");
//                 console.log(palletItemIds,"palletItemsIds");

//                 // Update palletItems in the original data with their corresponding IDs
//                 const updatedPalletItems = data.palletItems.map((item, index) => ({
//                     ...item,
//                     id: palletItemIds[index] || null  // Assign the ID if it exists, otherwise null
//                 }));

//                 // Create the final palletData object to send to Odoo
//                 const palletData = {
//                     pallet_id: palletId,  // From response
//                     store_id: data.store_id,
//                     category: data.category,
//                     pallet_type: data.pallet_type,
//                     weight: data.weight,
//                     built_by: data.built_by,
//                     status: data.status,
//                     other_notes: data.other_notes,
//                     contents: data.contents,
//                     last_status_changed_by: data.last_status_changed_by,
//                     last_status_changed_date: data.last_status_changed_date,
//                     date_created: data.date_created,
//                     palletItems: updatedPalletItems,  // Updated with IDs
//                     supplier: data.supplier,
//                     con_number: data.con_number,
//                 };

//                 // Log the updated pallet data and response for debugging
//                 console.log("================================");
//                 console.log('Response:', responseData);
//                 console.log('Pallet data:', palletData);
//                 console.log('Original data:', data);
//                 console.log("================================");
        
//                 // Dispatch the updated pallet data to send to Odoo
//                 dispatch(sendPalletToOdoo(palletData));
//                 return true;
//             })
//             .catch((error) => {
//                 // Stop loader and log the error if saving fails
//                 dispatch(stopLoaderAction('savePallet'));
//                 console.error('Error saving pallet:', error);
//                 return false;
//             });
//     }
// }

export const savePalletAction = (data) => {
    return (dispatch) => {
        dispatch(showLoaderAction('savePallet'));
        
        return savePallet(data)
            .then((response) => {
                dispatch(stopLoaderAction('savePallet'));

                

                // // Since the response is already a valid object, no need to parse it
                

                // Extract pallet ID and pallet item IDs directly from the response
                const palletId = response.pallet_id;
                
                const palletItemIds = response.pallet_item_ids || [];

                // console.log(palletId, "palletIDeeeeeeeeeeeeeeeeeeeeeee");
                // console.log(palletItemIds, "palletItemsIdssssssssssssssssssssss");

                 // Update palletItems in the original data with their corresponding IDs
                 const updatedPalletItems = data.palletItems && Array.isArray(data.palletItems) 
                 ? data.palletItems.map((item, index) => ({
                     ...item,
                     id: palletItemIds[index] || null  // Assign the ID if it exists, otherwise null
                 }))
                 : []; // If palletItems is undefined or not an array, use an empty array


                // console.log(palletId, "palletIDeeeeeeeeeeeeeeeeeeeeeee");
                // console.log(palletItemIds, "palletItemsIdssssssssssssssssssssss");
                // console.log(data.palletItems, "palletitems");

                // Create the final palletData object to send to Odoo
                const palletData = {
                    pallet_id: palletId,  // From response
                    store_id: data.store_id,
                    category: data.category,
                    pallet_type: data.pallet_type,
                    weight: data.weight,
                    built_by: data.built_by,
                    status: data.status,
                    other_notes: data.other_notes,
                    contents: data.contents,
                    last_status_changed_by: data.last_status_changed_by,
                    last_status_changed_date: data.last_status_changed_date,
                    date_created: data.date_created,
                    palletItems: updatedPalletItems,  // Updated with IDs
                    supplier: data.supplier,
                    con_number: data.con_number,
                };

                // Log the updated pallet data and response for debugging
                // console.log("================================");
                // console.log('Response:', response);
                // console.log('Pallet data:', palletData);
                // console.log('Original data:', data);
                // console.log("================================");
        
                // Dispatch the updated pallet data to send to Odoo
                dispatch(sendPalletToOdoo(palletData));
                return true;
            })
            .catch((error) => {
                // Stop loader and log the error if saving fails
                dispatch(stopLoaderAction('savePallet'));
                console.error('Error saving pallet:', error);
                return false;
            });
    }
}


export const updatePalletAction = (data) => {
    return (dispatch) => {
        dispatch(showLoaderAction('updatePallet'));

        return updatePallet(data)
            .then((response) => {
                dispatch(stopLoaderAction('updatePallet'));

                // Ensure pallet_id and built_by are mandatory
                if (!data.id || !data.built_by) {
                    console.error("pallet_id and built_by are required.");
                    return false;
                }

                // Filter out null or empty fields from the data object
                const filteredData = Object.keys(data).reduce((acc, key) => {
                    if (data[key] !== null && data[key] !== "") {
                        acc[key] = data[key];
                    }
                    return acc;
                }, {});

                // Construct palletData ensuring pallet_id and built_by are included
                const palletData = {
                    pallet_id: data.id,
                    built_by: data.built_by,
                    ...filteredData // Add only non-empty fields
                };

                // {console.log("================================")}
                // {console.log("Response 2",response);}
                // {console.log('pallet data 2',palletData);}
                // {console.log('data 222222222',data.palletItems);}
                // {console.log("================================")}
                
        
                dispatch(sendPalletToOdoo(palletData));
                
                return true;
            })
            .catch((error) => {
                dispatch(stopLoaderAction('updatePallet'));
                console.error("Error updating pallet:", error);
                return false;
            });
    }
}


export const sendPalletToOdoo = (palletData) => {
    return (dispatch) => {
        return updateOdooPalletData(palletData)
        .then((response) => {
            console.log('Pallet data sent to Odoo successfully');
        })
        .catch((error) => {
            console.error('Error sending pallet data to Odoo:', error);
        });
    };
};

export const savePalletItemAction = (data) => {
    
    return (dispatch) => {

        dispatch(showLoaderAction('savePalletItem'));

        return savePalletItem(data)
            .then((response) => {

                dispatch(stopLoaderAction('savePalletItem'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('savePalletItem'));
                return false;
            });
    }
}

export const deletePalletAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('deletePallet'));

        return deletePallet(data)
            .then((response) => {

                dispatch(stopLoaderAction('deletePallet'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('deletePallet'));
                return false;
            });
    }
}

export const deletePalletItemAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('deletePalletItem'));

        return deletePalletItem(data)
            .then((response) => {

                dispatch(stopLoaderAction('deletePalletItem'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('deletePalletItem'));
                return false;
            });
    }
}

export const updatePalletItemAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('updatePalletItem'));

        return updatePalletItem(data)
            .then((response) => {

                dispatch(stopLoaderAction('updatePalletItem'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('updatePalletItem'));
                return false;
            });
    }
}

export const receivedPalletShipperAction = (payload) => ({
    type: actionTypes.PALLET_SHIPPER_RECEIVED,
    payload
});

export const palletShipperAction = () => {

    return (dispatch) => {

        dispatch(showLoaderAction('palletShipper'));

        return palletShipper()
            .then((response) => {

                dispatch(stopLoaderAction('palletShipper'));
                dispatch(receivedPalletShipperAction(response));
            })
            .catch((error) => {

                dispatch(stopLoaderAction('palletShipper'));
            });
    }
}

export const updatePalletFormData = (payload) => ({
    type: actionTypes.PALLET_FORM_DATA,
    payload
});

export const clearFormData = (payload) => ({
    type: actionTypes.CLEAR_PALLET_FORM_DATA,
    payload
});

export const getItemDescriptionAction = (barcode) => {

    return () => {

        return getItemDescription(barcode)
            .then((response) => {

                return response;
            })
            .catch((error) => {
                return false;
            });
    }
}

// distribution module

export const getDIDdescriptionAction = (did_reference,barcode) => {

    return () => {

        return getDIDdescription(did_reference,barcode)
            .then((response) => {

                return response;
            })
            .catch((error) => {
                return false;
            });
    }
}


export const getITOdescriptionNewAction = (ito_reference,barcode) => {

    return () => {

        return getITOdescription_new(ito_reference,barcode)
            .then((response) => {

                return response;
            })
            .catch((error) => {
                return false;
            });
    }
}


export const getdidnumbervalidAction = (did_number) => {

    return () => {

        return getdidnumbervalid(did_number)
            .then((response) => {

                return response;
            })
            .catch((error) => {
                return false;
            });
    }
}

export const getitonumbervalidAction = (ito_number) => {

    return () => {

        return getitonumbervalid(ito_number)
            .then((response) => {

                return response;
            })
            .catch((error) => {
                return false;
            });
    }
}






export const getITODetailsAction = (data) => {

    return () => {

        return getITODetails(data)
            .then((response) => {

                return response;
            })
            .catch((error) => {
                return false;
            });
    }
}

export const syncPriceAction = () => {

    return (dispatch) => {

        dispatch(setSyncStatus(true));

        return syncPrice()
            .then((response) => {

                dispatch(syncPriceStatusAction());
            })
            .catch((error) => {

                dispatch(setSyncStatus(false));
            });
    }
}

export const syncPriceStatusAction = () => {

    return (dispatch) => {

        return syncPriceStatus()
            .then((response) => {

                if (response) {
                    response = response.indexOf('False') > -1;
                    dispatch(setSyncStatus(!response));
                }
                else {
                    dispatch(setSyncStatus(false));
                }
            })
            .catch((error) => {

                dispatch(setSyncStatus(false));
            });
    }
}

export const setSyncStatus = (payload) => ({
    type: actionTypes.PRICE_SYNC_STATUS,
    payload
});
